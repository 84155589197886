export const POPOVER_TYPES = ['lowstock', 'lowerafteradd', 'flash', 'cartcoupon']
import { isObject } from '@shein/common-function'

export const getNewPopOver = (step) => {
  const isPopoverType = POPOVER_TYPES.includes(step.type)
  const isPopover = step.isPopover || isPopoverType
  const tipsObj = isObject(step.tips)

  return {
    isNew: isPopover || tipsObj,
    info: tipsObj ? step.tips : step,
  }
}
