<template>
  <span
    :style="styles"
    :class="classes"
  >
    <slot></slot>
  </span>
</template>

<script>
/**
 * @description 自适应字体大小,缩放到最小值后不再缩放溢出隐藏
 * @example <ResizeFont><ResizeFontText :init-font-size="14" :resize-font-min="10" :resize-font-step="2">内容</ResizeFontText></ResizeFont>
 */
export default {
  name: 'ResizeFont',

  provide() {
    return {
      registerComponent: this.registerComponent,
    }
  },
  
  props: {
    /**
     * @description 是否回退多行
     */
    isMultiline: {
      type: Boolean,
      default: false
    },
    /**
     * @description 多行数
     */
    multilineNum: {
      type: Number,
      default: 2
    },
    /**
     * @description 初始化的时机
     */
    trigger: {
      type: String,
      default: 'Observer',
      validator: (val) => ['Mounted', 'Observer'].includes(val)
    }
  },

  data() {
    return {
      children: [],
      isOverflowing: false,
      isResizeDone: false,
    }
  },

  computed: {
    classes() {
      return [
        'resize-font',
        this.isResizeDone && this.isMultiline && 'resize-font_multiline',
        this.isResizeDone && !this.isMultiline && 'resize-font_ellipsis',
      ]
    },
    styles() {
      return {
        '--multiline-num': this.multilineNum
      }
    }
  },

  mounted() {
    this.handleInit()
  },

  methods: {
    /**
     * 注册子组件
     */
    registerComponent(child) {
      this.children.push(child)
    },
    /**
     * 初始化
     */
    handleInit() {
      if (this.trigger === 'Mounted') {
        this.onMounted()
      } else {
        this.onObserver()
      }
    },

    /**
     * 重新计算字体大小
     */
    update() {
      // 重置子组件的状态
      this.children.forEach(child => {
        child.resetResizeDone()
      })

      // 重置组件状态
      this.isOverflowing = false
      this.isResizeDone = false

      // 重新计算
      this.handleInit()
    },

    /**
     * 挂载时时初始化
     */
    onMounted() {
      this.handleResizeFontSize()
    },

    /**
     * 视口初始化
     */
    onObserver() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.handleResizeFontSize()
            observer.disconnect()
          }
        })
      })
      observer.observe(this.$el) 
    },

    /**
     * 处理自适应字体大小
     */
    handleResizeFontSize(el = this.$el) {
      this.isOverflowing = el.clientWidth < el.scrollWidth
      // 溢出状态
      if (this.isOverflowing) {
        if (!this.getResizeDone()) {
          this.resizeFontSize()
          this.$nextTick(() => this.handleResizeFontSize())
        } else {
          this.isResizeDone = true
        }
      }
    },

    /**
     * 调用当前子组件的 resizeFontSize 方法
     */
    resizeFontSize() {
      this.children.forEach(child => {
        child.resizeFontSize()
      })
    },

    /**
     * 是否缩放完毕
     */
    getResizeDone() {
      return this.children.every(child => child.isResizeDone)
    }
  }
}
</script>

<style lang="less" scoped>
.resize-font {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  &_multiline {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: var(--multiline-num, 2);
    -webkit-box-orient: vertical;
    text-align: center;
    white-space: normal;
    word-break: break-all;
  }
  &_ellipsis {
    text-overflow: ellipsis;
  }
}
</style>

