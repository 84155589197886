<template>
  <div 
    :class="classnames"
  >
    <div
      v-if="info.iconText"
      class="top"
    >
      <ResizeFont
        ref="refResizeFont"
        :is-multiline="info.lurePointItemType == 7"
        :multiline-num="2"
      >
        <ResizeFontText
          :init-font-size="12"
          :resize-font-min="8"
          :resize-font-step="1"
        >
          {{ info.iconText }}
        </ResizeFontText>
      </ResizeFont>
    </div>
    <div
      v-if="info.iconTextOff"
      class="bottom"
    >
      {{ info.iconTextOff }}
    </div>
  </div>
</template>

<script>
import ResizeFont from '@/public/src/pages/cartNew/components/ResizeFont.vue'
import ResizeFontText from '@/public/src/pages/cartNew/components/ResizeFontText.vue'

export default {
  name: 'FooterPopoverCoupon',
  components: {
    ResizeFont,
    ResizeFontText,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    classnames() {
      const cls = ['popover-returncoupon']
      if (this.info.lurePointItemType == 7) cls.push('freeshipping')
      if (this.info.lurePointItemType == 8) cls.push('pricefreeshipping')
      if (this.info.couponNum > 1) cls.push('package')
      return cls
    }
  },
  watch: {
    'info.iconText'() {
      this.$refs.refResizeFont.update()
    }
  }
}
</script>

<style lang="less" scoped>
.popover-returncoupon {
  width: 60/37.5rem;
  height: 38/37.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/08/15/fd/1723731386961755c0bf2f14352a84407fd26afb6a.png);
  background-repeat: no-repeat;
  &.freeshipping {
    width: 72/37.5rem;
    background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/08/16/23/1723789358c120937289f3188188d4859e199d4c61.png);
    background-size: 100% 100%;
    .top {
      width: 82%;
      color: #169E00;
    }
    .bottom {
      color: #169E00;
    }
  }
  &.pricefreeshipping {
    color: #169E00;
    background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/08/15/b7/17237314508c79e292f40a65784c5d638d43fb39ba.png);
    .top {
      color: #169E00;
    }
    .bottom {
      color: #169E00;
    }
  }
  &.package {
    width: 81/37.5rem;
    background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/08/15/06/1723731330e111bd344f80ce19d63b2253aa5f12e1.png);
    .top {
      width: 64%;
    }
    .bottom {
      width: 64%;
    }
  }
  .top {
    color: #3A2614;
    width: 88%;
    line-height: 1;
    font-size: 12px;
    font-weight: 1000;
    overflow: hidden;
    text-align: center;
  }
  .bottom {
    margin: 0 auto;
    max-width: 56/37.5rem;
    margin-top: 2/37.5rem;
    width: 88%;
    line-height: 8px;
    font-size: 8px;
    font-weight: 400;
    color: #4E3C2A;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
