<template>
  <div class="popover-goods">
    <template v-if="showList.length">
      <div
        v-for="(item, i) in showList"
        :key="i"
        class="goods"
      >
        <img
          :src="item.goods_img || item.goodsImg"
          width="100%"
          height="100%"
        />

        <div
          v-show="getShowDiscount(item)"
          class="unit-discount"
        >
          <span>{{
            (lang != 'il' ? '-' : '') + item.unit_discount + '%'
          }}</span>
        </div>

        <div
          v-if="type === 'gift'"
          class="belt belt__gift"
        >
          GIFT
        </div>

        <div
          v-if="i == 1"
          class="modal"
        >
          <span v-if="modalText">{{ modalText }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-if="type === 'gift'">
        <img
          height="38px"
          src="https://img.ltwebstatic.com/images3_ccc/2024/08/15/44/1723693283ff5b69a86e97bca2e06d713790c69099.png"
        />
      </div>
    </template>
  </div>
</template>

<script>
const { lang, IS_SUGGESTED } = gbCommonInfo

export default {
  name: 'FooterPopoverGoods',
  props: {
    type: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    goodsNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      lang
    }
  },
  computed: {
    showList() {
      return (this.list || []).slice(0, 2)
    },
    modalText() {
      let length = this.list?.length
      if (this.goodsNum) length = this.goodsNum
      if (length >= 2 && length <= 100) return `+${length - 1}`
      if (length > 100) return '99+'
      return ''
    }
  },
  methods: {
    getShowDiscount(item) {
      return !IS_SUGGESTED && this.type !== 'gift' && item.unit_discount != 0
    }
  }
}
</script>

<style lang="less" scoped>
.popover-goods {
  display: flex;
  .goods {
    position: relative;
    width: 38/37.5rem;
    height: 38/37.5rem;
    margin-right: 4/37.5rem;
    .unit-discount {
      position: absolute;
      right: auto;
      text-align: center;
      font-size: 10px;
      color: #fff;
      direction: ltr /* rtl:ignore */;
      /* sh:begin */
      min-width: 2.75em;
      line-height: 1.35;
      top: 0;
      left: 0 /* rtl:ignore */;
      right: unset /* rtl:ignore */;
      background: @sui_color_discount;
    }
    .belt {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 12px;
      line-height: 12px;
      font-size: 10px;
      text-align: center;

      &__gift {
        background: rgba(#fa6338, 0.85);
        color: #fff;
      }
    }
    .modal {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.7);
      font-size: 12px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
